const $ = require('jquery');
const qs = require('qs');

const Page = require('../components/Page');

Page.create('.node-type-thank-you-page', () => {
  const query = qs.parse(window.location.search);

  /**
   * Name of property that directed us to this page
   *
   * @type {string}
   */
  const propertyName = query.pn;

  if (propertyName) {
    $('#property-name').text(propertyName);
  }
});
