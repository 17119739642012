const $ = require('jquery');
const merge = require('merge-deep');
const throttle = require('lodash/throttle');

const Shadowbox = require('./Shadowbox');

// Make sure the hero is always full height
const $window = $(window);
const $page = $('#page');

module.exports = class Hero {
  /** Default jQuery selector for the component */
  static get DEFAULT_SELECTOR() {
    return '.hero';
  }

  /** Default configuration object */
  static get DEFAULT_OPTIONS() {
    return {
      selectors: {
        image: '.hero__image',
        popupVideo: '.field-name-hero-youtube',
        fullHeight: '.front',
      },

      classes: {
        withPopup: 'js-with-popup',
      },
    };
  }

  /** Creates an instance of the component. */
  static create(...args) {
    return new this(...args);
  }

  /**
   * Constructor for the Component
   *
   * @param {string|Element|JQuery} selector
   * @param {Object} options
   */
  constructor(selector = Hero.DEFAULT_SELECTOR, options = {}) {
    this.config = merge(Hero.DEFAULT_OPTIONS, options);

    this.$hero = $(selector);
    this.$image = $(this.config.selectors.image);
    this.$popupVideo = $(this.config.selectors.popupVideo);

    this.isFullHeight = $(this.config.selectors.fullHeight).length > 0;

    this.updateHeight();

    this.addListeners();
    this.render();
  }

  addListeners() {
    $window.on('resize', throttle(() => this.updateHeight(), 60));
  }

  updateHeight() {
    if (!this.isFullHeight) {
      return;
    }

    // If the admin menu is present, it pushes the #page contents down by 28px,
    // so we need to take into account the page offset when setting the height.
    this.$hero.height($window.height() - $page.offset().top);
  }

  render() {
    if (this.$popupVideo.length) {
      Shadowbox.create(this.$image, { content: this.$popupVideo });

      const $playIcon = $(`
        <svg xmlns="http://www.w3.org/2000/svg" class="hero__play-icon play-icon" width="60" height="60" version="1.1">
          <path class="play-icon__background" stroke-width="3" d="M30 1.5C14.272 1.5 1.5 14.272 1.5 30S14.272 58.5 30 58.5 58.5 45.728 58.5 30 45.728 1.5 30 1.5z"/>
          <path class="play-icon__triangle" d="M26.194 41.61V18.39L37.806 30z"/>
        </svg>
      `);

      this.$image.addClass(this.config.classes.withPopup).append($playIcon);
    }
  }
};
