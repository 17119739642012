const $ = require('jquery');
const merge = require('merge-deep');
const justifyLayout = require('justified-layout');

const Shadowbox = require('./Shadowbox');

/**
 * @typedef ContainerPadding
 *
 * @property {Number} top
 * @property {Number} bottom
 * @property {Number} left
 * @property {Number} right
 */

/**
 * @typedef BoxSpacingAxes
 *
 * @property {Number} horizontal
 * @property {Number} vertical
 */

/**
 * @typedef GalleryConfig
 *
 * @property {Number} containerWidth
 *   The width that boxes will be contained within irrelevant of padding.
 *
 * @property {Number|ContainerPadding} containerPadding
 *   Padding inside the container itself.
 *
 * @property {Number|BoxSpacingAxes} boxSpacing
 *   Spacing between boxes.
 *
 * @property {Number} targetRowHeight
 *   Desired height of each row.
 *
 *   It's called a target because row height is the lever we use in order to fit
 *   everything in nicely. The algorithm will get as close to the target row
 *   height as it can.
 *
 * @property {Number} targetRowHeightTolerance
 *   How far row heights can stray from `targetRowHeight`.
 *
 *   The value must be between `0` and `1`.
 *
 *   `0` would force rows to be the targetRowHeight exactly and would likely
 *   make it impossible to justify.
 *
 * @property {Number} maxNumRows
 *   Maximum number of rows to add, regardless regardless of how many items
 *   still need to be laid out.
 *
 * @property {Number|Boolean} forceAspectRatio
 *   If a number is provided, forces images to follow the provided aspect ratio.
 *
 *   Makes the values in your input array irrelevant. However, the length of the
 *   array remains relevant.
 *
 * @property {Boolean} showWidows
 *   Whether items at the end of a justified layout can be included, even if
 *   they don't make a full row.
 *
 * @property {Number|Boolean} fullWidthBreakoutRowCadence
 *   If a `Number`, insert a full width box every `n` rows.
 *
 *   The box on that row will ignore the targetRowHeight, make itself as
 *   wide as `containerWidth - containerPadding` and be as tall as its aspect
 *   ratio defines.
 *
 *   It'll only happen if that item has an aspect ratio >= 1 (i.e. images that
 *   are either square or landscape).
 *
 * @property {Number} introRows
 *   Number of rows to show in the intro box
 *
 * @property {Object} classes
 *   CSS class names
 *
 * @property {Object} selectors
 *   CSS selectors
 */

/**
 * @typedef LayoutBox
 *
 * @property {Number} aspectRatio
 * @property {Number} top
 * @property {Number} left
 * @property {width} width
 * @property {height} height
 */

/**
 * @typedef Layout
 *
 * @property {Number} containerHeight
 * @property {Number} widowCount
 * @property {LayoutBox[]} boxes
 */

// -----------------------------------------------------------------------------

/**
 * Box to use for hidden images, e.g. widows
 *
 * @type {LayoutBox}
 */
const HIDDEN_BOX = {
  width: 0,
  height: 0,
  left: 0,
  top: 0,
  aspectRatio: NaN,
};

module.exports = class JustifiedGallery {
  /** Default jQuery selector for the component */
  static get DEFAULT_SELECTOR() {
    return '.gallery';
  }

  /**
   * Default configuration options
   *
   * @see http://flickr.github.io/justified-layout/#options
   *
   * @type {GalleryConfig}
   */
  static get DEFAULT_OPTIONS() {
    return {
      containerWidth: 1060,
      containerPadding: 10,
      boxSpacing: 10,
      targetRowHeight: 320,
      targetRowHeightTolerance: 0.25,
      maxNumRows: Number.POSITIVE_INFINITY,
      forceAspectRatio: false,
      showWidows: false,
      fullWidthBreakoutRowCadence: false,

      introRows: 2,

      selectors: {
        gallery: '.gallery',
        itemList: '.gallery__image-list',
        item: '.gallery__image',
      },

      classes: {
        justified: 'is-justified',
        introItemList: 'gallery__image-list--intro',
        overflowItemList: 'gallery__image-list--overflow',
        toggler: 'gallery__toggler',
      },

      messages: {
        SHOW_MORE: 'Show More Photos',
        HIDE: 'Hide Photos',
      },
    };
  }

  /**
   * Creates an instance of the component.
   *
   * Useful when creating a component primarily for its side effects
   *
   * @see http://eslint.org/docs/rules/no-new
   */
  static create(...args) {
    return new this(...args);
  }

  /**
   * Maps over an array, calculating each element's aspect ratio
   *
   * @param {Number} index
   * @param {HTMLImageElement} el
   *
   * @return {Number}
   */
  static calcAspectRatio(index, el) {
    const width = el.getAttribute('width');
    const height = el.getAttribute('height');

    return width / height;
  }

  /**
   * Updates each image in a list
   *
   * @param {JQuery} $container
   * @param {JQuery} $items
   * @param {Layout} layout
   */
  static renderItemList($container, $items, layout) {
    $items.each(JustifiedGallery.placeImage(layout));
    $container.append($items).data('container-height', layout.containerHeight);
  }

  /**
   * Creates a function that updates an images CSS with the provided layout box
   *
   * @param {LayoutBox[]} layout
   * @return {Function}
   */
  static placeImage(layout) {
    return (index, el) => {
      /** @type {LayoutBox} */
      const box = layout.boxes[index] || HIDDEN_BOX;

      $(el).css({
        width: box.width,
        height: box.height,
        top: box.top,
        left: box.left,
      });
    };
  }

  /**
   * Constructor for the component
   *
   * @param {string|Element|JQuery} selector
   * @param {GalleryConfig} options
   */
  constructor(selector = JustifiedGallery.DEFAULT_SELECTOR, options = {}) {
    /** @type {GalleryConfig} */
    this.config = merge(JustifiedGallery.DEFAULT_OPTIONS, options);

    this.$gallery = $(selector);

    this.$itemList = $(this.config.selectors.itemList);

    this.$overflowItemList = this.$itemList
      .clone()
      .empty()
      .addClass(this.config.classes.overflowItemList);

    this.$itemList.addClass(this.config.classes.introItemList);

    this.$items = this.$itemList.find(this.config.selectors.item);

    this.$toggler = $(`<button class="${this.config.classes.toggler}">`);

    this.boxes = this.$items
      .find('img')
      .map(JustifiedGallery.calcAspectRatio)
      .get();

    this.state = {
      isOpen: false,
    };

    this.addListeners();
    this.render();

    Shadowbox.create(this.$gallery, {
      filter: 'a',
      isGallery: true,
    });
  }

  addListeners() {
    this.$toggler.on('click', () => this.toggle());
  }

  /**
   * Updates the container width, and re-renders the gallery
   *
   * @param {Number} width
   */
  setWidth(width) {
    this.config.containerWidth = width;
    this.render();
  }

  setState(newState = {}) {
    Object.assign(this.state, newState);
    this.render();
  }

  toggle() {
    if (this.state.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this.setState({ isOpen: true });
  }

  close() {
    this.setState({ isOpen: false });
  }

  /**
   * @param {LayoutBox[]} boxes
   * @param {GalleryConfig} opts
   *
   * @return {Layout}
   */
  calcLayout(boxes, opts = {}) {
    return justifyLayout(boxes, merge(this.config, opts));
  }

  render() {
    this.$items.detach();

    this.$gallery
      .append(this.$overflowItemList)
      .addClass(this.config.classes.justified);

    // Update the image layouts
    // -------------------------------------------------------------------------
    const introLayout = this.calcLayout(this.boxes, {
      maxNumRows: this.config.introRows,
      showWidows: false,
    });

    const overflowBoxes = this.boxes.slice(introLayout.boxes.length);
    const overflowLayout = this.calcLayout(overflowBoxes);

    const $introItems = this.$items.slice(0, introLayout.boxes.length);
    const $overflowItems = this.$items.slice(introLayout.boxes.length);

    if ($introItems.length) {
      // We have enough intro items to make at least one full row, so output
      // the intro items...
      JustifiedGallery.renderItemList(this.$itemList, $introItems, introLayout);

      // ... Followed by any images that don't fit in the intro section
      JustifiedGallery.renderItemList(
        this.$overflowItemList,
        $overflowItems,
        overflowLayout
      );
    } else if ($overflowItems.length) {
      // Not enough intro items to make a full row, so all we have is overflow
      // items. Instead of rendering an empty row, output the overflow items
      // in the normal position.
      JustifiedGallery.renderItemList(
        this.$itemList,
        $overflowItems,
        overflowLayout
      );
    }

    // Adjust the height of the item list container
    this.$itemList.height(this.$itemList.data('container-height'));

    // The overflow items need to animate their height based on whether the
    // gallery is currently open or closed. The `stop()` call prevents previous
    // animations from interfering with the new one.
    this.$overflowItemList.stop().animate({
      height: this.state.isOpen
        ? this.$overflowItemList.data('container-height')
        : 0,
    });

    // Update the toggle button
    // -------------------------------------------------------------------------
    this.$toggler.detach();

    // Only output the toggle text if we have intro items _and_ overflowed
    // items. Because we sometimes output `$overflowItems` in the normal intro
    // position, we have to check for both possibilities.
    if ($introItems.length && $overflowItems.length) {
      const togglerText = this.state.isOpen
        ? this.config.messages.HIDE
        : this.config.messages.SHOW_MORE;

      this.$toggler.insertAfter(this.$gallery).text(togglerText);
    }
  }
};
