const $ = require('jquery');
const merge = require('merge-deep');

/**
 * Default configuration
 *
 * @type {Object}
 */
const DEFAULT_CONFIG = {
  timerKey: '@@highlight/timer',
  duration: 2000,
  classes: {
    HIGHLIGHTED: 'is-highlighted',
  },
};

$.fn.highlight = function highlight(opts = {}) {
  const config = merge(DEFAULT_CONFIG, opts);

  const $el = $(this);

  if ($el.data(config.timerKey)) {
    clearTimeout($el.data(config.timerKey));
  }

  $el.addClass(config.classes.HIGHLIGHTED);

  const timer = window.setTimeout(
    () => $el.removeClass(config.classes.HIGHLIGHTED),
    config.duration
  );

  $el.data(config.timerKey, timer);
};
