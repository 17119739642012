/* eslint-disable no-param-reassign */

const $ = require('jquery');
const merge = require('merge-deep');

const $doc = $('html, body');

/**
 * Default configuration
 *
 * @type {Object}
 */
const DEFAULT_CONFIG = {
  margin: 50,
  edge: 'top',
  duration: 750,
  easing: 'swing',
};

/**
 * Create a plugin that smoothly scrolls to the element it's been assigned to
 *
 * @example
 * $('.some-element').smoothScrollTo();
 */
$.fn.smoothScrollTo = function smoothScrollTo(opts = {}) {
  const $target = $(this);

  if (!$target.length) {
    return;
  }

  const config = merge(DEFAULT_CONFIG, opts);

  let scrollTop = $target.offset().top - config.margin;

  if (config.edge === 'bottom') {
    scrollTop += $target.outerHeight();
  }

  $doc.stop().animate(
    { scrollTop },
    {
      duration: config.duration,
      easing: config.easing,
      start: () => $target.trigger('scrolling-to'),
      complete: () => $target.trigger('scrolled-to'),
    }
  );
};

// Attach the plugin to all page-internal links
$(document).on('click', 'a[href^="#"]:not(".no-scroll")', e => {
  e.preventDefault();

  const $target = $(e.currentTarget);
  const targetHref = $target.attr('href');

  if (targetHref === '#') {
    return;
  }

  $target.blur();
  $(targetHref).smoothScrollTo();
});
