const $ = require('jquery');
const merge = require('merge-deep');

// Adds itself as a jQuery plugin
require('mailcheck');

module.exports = class Textfield {
  /** Default jQuery selector for the component */
  static get DEFAULT_SELECTOR() {
    return `
      [type="text"],
      [type="tel"],
      [type="email"],
      [type="password"],
      textarea
    `;
  }

  /** Default configuration options */
  static get DEFAULT_OPTIONS() {
    return {
      classes: {
        filled: 'is-filled',
        focus: 'is-focused',
      },

      selectors: {
        wrapper: '.webform-component, .form-item',
        label: 'label',
      },
    };
  }

  static create(...args) {
    return new this(...args);
  }

  /**
   * Creates multiple instances of this component that do not share event
   * listeners.
   */
  static createMultiple(selector = Textfield.DEFAULT_SELECTOR, opts = {}) {
    $(selector).toArray().forEach(el => this.create(el, opts));
  }

  /**
   * Constructor for the component
   *
   * @param {string|Element|JQuery} selector
   * @param {GalleryConfig} opts
   */
  constructor(selector = Textfield.DEFAULT_SELECTOR, options = {}) {
    this.config = merge(Textfield.DEFAULT_OPTIONS, options);

    this.$el = $(selector);
    this.$wrapper = this.$el.closest(this.config.selectors.wrapper);

    /** @type {JQuery} */
    this.$suggestion = null;

    /** @type {JQuery} */
    this.$suggestionHint = null;

    /** @type {JQuery} */
    this.$suggestionAddress = null;

    /** @type {JQuery} */
    this.$suggestionDomain = null;

    this.state = {
      isFilled: this.$el.val(),
      isFocused: false,
      suggestion: null,
    };

    this.isEmail = this.$el.attr('type') === 'email';

    if (this.isEmail) {
      this.$suggestion = $(`
        <span class="suggestion">
          Did you mean <a class="suggestion__hint" href="#"><span class="suggestion__address"></span>@<span class="suggestion__domain"></span></a>?
        </span>
      `);

      this.$suggestionHint = this.$suggestion.find('a');
      this.$suggestionAddress = this.$suggestion.find('.suggestion__address');
      this.$suggestionDomain = this.$suggestion.find('.suggestion__domain');
    }

    this.addListeners();
    this.render();
  }

  addListeners() {
    this.$el.on('change', () => this.handleChange());
    this.$el.on('focus', () => this.handleFocus());
    this.$el.on('blur', () => this.handleBlur());

    if (this.$suggestionHint) {
      this.$suggestionHint.on('click', e => this.handleSuggestionClick(e));
    }
  }

  setState(newState = {}) {
    Object.assign(this.state, newState);

    this.render();
  }

  handleChange() {
    this.setState({ isFilled: this.$el.val() });
  }

  handleFocus() {
    this.setState({ isFocused: true });
  }

  handleBlur() {
    this.setState({ isFocused: false });

    if (this.isEmail) {
      this.checkEmail();
    }
  }

  handleSuggestionClick(e) {
    e.preventDefault();

    this.$el.val(this.state.suggestion.full);
    this.setState({ suggestion: null });
  }

  checkEmail() {
    const domains = [
      'gmail.com',
      'yahoo.com',
      'hotmail.com',
      'outlook.com',
      'aol.com',
    ];

    this.$el.mailcheck({
      suggested: ($el, suggestion) => this.setState({ suggestion }),
      empty: () => this.setState({ suggestion: null }),

      domains,
    });
  }

  render() {
    if (this.isEmail) {
      const { suggestion } = this.state;

      if (suggestion) {
        this.$suggestionAddress.text(suggestion.address);
        this.$suggestionDomain.text(suggestion.domain);
        this.$suggestion.appendTo(this.$wrapper);
      } else {
        this.$suggestion.detach();
      }

      this.$wrapper.toggleClass('has-suggestion', Boolean(suggestion));
    }

    this.$wrapper.toggleClass(
      this.config.classes.filled,
      Boolean(this.state.isFilled)
    );

    this.$wrapper.toggleClass(
      this.config.classes.focus,
      Boolean(this.state.isFocused)
    );
  }
};
