const $ = require('jquery');
const Viewport = require('@altos/viewport');

const Page = require('../components/Page');
const Drawer = require('../components/Drawer');
const Gallery = require('../components/JustifiedGallery');

Page.create('body.node-type-property', () => {
  // Contact Form
  // ---------------------------------------------------------------------------
  const $contactForm = $('#contact');

  $contactForm.on('scrolling-to', () => {
    $contactForm.highlight({ duration: 1000 });
  });

  // Gallery
  // ---------------------------------------------------------------------------
  const gallery = new Gallery('.gallery', {
    containerPadding: 0,
    containerWidth: Viewport.width(),
    boxSpacing: 1,
    targetRowHeight: 350,
    showWidows: true,
    introRows: 2,

    classes: {
      toggler: 'gallery__toggler button button--secondary',
    },
    messages: {
      SHOW_MORE: 'Show More Photos',
      HIDE: 'Collapse Photos',
    },
  });

  $(window).on('resize', () => {
    gallery.setWidth(Viewport.width());
  });

  // Drawers
  // ---------------------------------------------------------------------------
  Drawer.createMultiple('.property-drawer', {
    selectors: {
      items: '.property-icon--with-drawer',
      itemToggler: '.property-icon__button',
      itemContent: '.property-icon__related-items',
    },
  });
});
