const $ = require('jquery');
const Drupal = require('Drupal');

const $document = $(document);

// =============================================================================
// Attach Behaviors
// =============================================================================

$document.on('ready', () => {
  if (Drupal.clientsideValidation) {
    Drupal.clientsideValidation.prototype.handleErrorPlacement = handleErrorPlacement;
  }

  if (Drupal.webform) {
    Drupal.webform.doConditions = handleWebformConditional;
  }
});

// =============================================================================
// Clientside Validation
// =============================================================================

const classes = {
  ERROR: 'has-error',
};

/**
 * Remove error message containers after a field has been marked as valid
 *
 * Without this, the `clientside_validation` module only adds a 'display: none'
 * to the style attribute of the error message container. This makes it harder
 * to style things, because the error message is still in the DOM even after
 * a field is valid.
 *
 * @see http://cgit.drupalcode.org/clientside_validation/tree/clientside_validation.js#n325
 */
$document.on('clientsideValidationValid', e => {
  const $container = $(e.target).closest('.webform-component');
  const $error = $container.find('.error');

  $container.removeClass(classes.ERROR);
  $error.remove();
});

/**
 * Scrolls to the first element with an error message
 *
 * clientside_validation triggers this event before it triggers individual
 * form errors, so we can scroll to the first error by attaching a one-time
 * event and scrolling to the container.
 */
$document.on('clientsideValidationFormHasErrors', () => {
  $document.one('clientsideValidationInvalid', e => {
    const $container = $(e.target).closest('.webform-component');
    $container.addClass(classes.ERROR);
  });
});

/**
 * Insert clientside validation error messages at the end of the .form-item
 *
 * This is especially useful when using libraries like Chosen or Selectric to
 * add custom styling to <select> elements. These libraries wrap the original
 * element in a <div> with `display: none`, so if you try to add the error
 * message after the label, it will never be displayed.
 *
 * To enable:
 *
 *   1. Visit: /admin/config/validation/clientside_validation/default#edit-error-placement
 *   2. Set 'Default Location' to 'Custom function'
 *   3. Set 'Custom function name' to 'handleErrorPlacement';
 *
 * ----
 *
 * @param {jQuery} $error - Error message element (as jQuery object)
 * @param {jQuery} $input - Original form input (as jQuery object)
 * @return {void}
 */
function handleErrorPlacement($error, $input) {
  const $container = $input.closest('.webform-component');
  $container.addClass(classes.ERROR);

  if ($input.is(':checkbox, :radio')) {
    const $label = $container.children('label');
    $label.after($error);
  } else {
    $container.append($error);
  }
}

// =============================================================================
// Webform
// -----------------------------------------------------------------------------

/* eslint-disable */
// prettier-ignore

/**
 * Override the webform.doConditions to make the hiding / showing animate,
 * rather than just popping into place.
 */
function handleWebformConditional($form, settings) {

    var stackPointer;
    var resultStack;

    /**
     * Initializes an execution stack for a conditional group's rules and
     * sub-conditional rules.
     */
    function executionStackInitialize(andor) {
      stackPointer = -1;
      resultStack = [];
      executionStackPush(andor);
    }

    /**
     * Starts a new subconditional for the given and/or operator.
     */
    function executionStackPush(andor) {
      resultStack[++stackPointer] = {
        results: [],
        andor: andor,
      };
    }

    /**
     * Adds a rule's result to the current sub-condtional.
     */
    function executionStackAccumulate(result) {
      resultStack[stackPointer]['results'].push(result);
    }

    /**
     * Finishes a sub-conditional and adds the result to the parent stack frame.
     */
    function executionStackPop() {
      // Calculate the and/or result.
      var stackFrame = resultStack[stackPointer];
      // Pop stack and protect against stack underflow.
      stackPointer = Math.max(0, stackPointer - 1);
      var $conditionalResults = stackFrame['results'];
      var filteredResults = $.map($conditionalResults, function(val) {
        return val ? val : null;
      });
      return stackFrame['andor'] === 'or'
                ? filteredResults.length > 0
                : filteredResults.length === $conditionalResults.length;
    }

    // Track what has be set/shown for each target component.
    var targetLocked = [];

    $.each(settings.ruleGroups, function (rgid_key, rule_group) {
      var ruleGroup = settings.ruleGroups[rgid_key];

      // Perform the comparison callback and build the results for this group.
      executionStackInitialize(ruleGroup['andor']);
      $.each(ruleGroup['rules'], function (m, rule) {
        switch (rule['source_type']) {
          case 'component':
            var elementKey = rule['source'];
            var element = $form.find('.' + elementKey)[0];
            var existingValue = settings.values[elementKey] ? settings.values[elementKey] : null;
            executionStackAccumulate(window['Drupal']['webform'][rule.callback](element, existingValue, rule['value']));
            break;
          case 'conditional_start':
            executionStackPush(rule['andor']);
            break;
          case 'conditional_end':
            executionStackAccumulate(executionStackPop());
            break;
        }
      });
      var conditionalResult = executionStackPop();

      $.each(ruleGroup['actions'], function (aid, action) {
        var $target = $form.find('.' + action['target']);
        var actionResult = action['invert'] ? !conditionalResult : conditionalResult;
        switch (action['action']) {
          case 'show':
            if (actionResult != Drupal.webform.isVisible($target)) {
              var $targetElements = actionResult
                                      ? $target.find('.webform-conditional-disabled').removeClass('webform-conditional-disabled')
                                      : $target.find(':input').addClass('webform-conditional-disabled');
              $targetElements.webformProp('disabled', !actionResult);
              $target.toggleClass('webform-conditional-hidden', !actionResult);
              if (actionResult) {
                // ORIGINAL:
                // $target.show();
                $target.slideDown();
              }
              else {
                // ORIGINAL:
                // $target.hide();
                $target.slideUp();
                // Record that the target was hidden.
                targetLocked[action['target']] = 'hide';
              }
              if ($target.is('tr')) {
                Drupal.webform.restripeTable($target.closest('table').first());
              }
            }
            break;
          case 'require':
            var $requiredSpan = $target.find('.form-required, .form-optional').first();
            if (actionResult != $requiredSpan.hasClass('form-required')) {
              var $targetInputElements = $target.find("input:text,textarea,input[type='email'],select,input:radio,input:file");
              // Rather than hide the required tag, remove it so that other jQuery can respond via Drupal behaviors.
              Drupal.detachBehaviors($requiredSpan);
              $targetInputElements
                .webformProp('required', actionResult)
                .toggleClass('required', actionResult);
              if (actionResult) {
                $requiredSpan.replaceWith('<span class="form-required" title="' + Drupal.t('This field is required.') + '">*</span>');
              }
              else {
                $requiredSpan.replaceWith('<span class="form-optional"></span>');
              }
              Drupal.attachBehaviors($requiredSpan);
            }
            break;
          case 'set':
            var isLocked = targetLocked[action['target']];
            var $texts = $target.find("input:text,textarea,input[type='email']");
            var $selects = $target.find('select,select option,input:radio,input:checkbox');
            var $markups = $target.filter('.webform-component-markup');
            if (actionResult) {
              var multiple = $.map(action['argument'].split(','), $.trim);
              $selects.webformVal(multiple);
              $texts.val([action['argument']]);
              // A special case is made for markup. It is sanitized with filter_xss_admin on the server.
              // otherwise text() should be used to avoid an XSS vulnerability. text() however would
              // preclude the use of tags like <strong> or <a>
              $markups.html(action['argument']);
            }
            else {
              // Markup not set? Then restore original markup as provided in
              // the attribute data-webform-markup.
              $markups.each(function() {
                var $this = $(this);
                var original = $this.data('webform-markup');
                if (original !== undefined) {
                  $this.html(original);
                }
              });
            }
            if (!isLocked) {
              // If not previously hidden or set, disable the element readonly or readonly-like behavior.
              $selects.webformProp('disabled', actionResult);
              $texts.webformProp('readonly', actionResult);
              targetLocked[action['target']] = actionResult ? 'set' : false;
            }
            break;
        }
      }); // End look on each action for one conditional
    }); // End loop on each conditional

}
/* eslint-enable */
