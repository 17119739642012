const $ = require('jquery');

module.exports = class DropCap {
  /** Default jQuery selector for the component */
  static get DEFAULT_SELECTOR() {
    return '.drop-cap';
  }

  /**
   * Creates an instance of the component.
   *
   * Useful when creating a component primarily for its side effects
   *
   * @see http://eslint.org/docs/rules/no-new
   */
  static create(...args) {
    return new this(...args);
  }

  static createMultiple(selector) {
    $(selector).toArray().forEach(el => this.create(el));
  }

  /**
   * Constructor for the Component
   *
   * @param {string|Element|JQuery} selector
   */
  constructor(selector = DropCap.DEFAULT_SELECTOR) {
    const $text = $(selector);

    const $letter = $('<div>')
      .addClass('drop-cap__letter')
      .attr('aria-role', 'presentation')
      .attr('data-letter', $text.text().trim().charAt(0));

    $text
      .addClass('drop-cap')
      .wrapInner('<div class="drop-cap__copy">')
      .wrapInner('<div class="drop-cap__inner">');

    $text.children().first().prepend($letter);
  }
};
