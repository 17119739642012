module.exports = class Page {
  static create(...args) {
    return new this(...args);
  }

  /**
   * Conditionally initializes a page
   *
   * @param {string|Element|JQuery} pageSelector
   *   jQuery selector used to test if we're on the page
   *
   * @param {function} callback
   *   Callback to fire if the page is current
   */
  constructor(pageSelector, callback) {
    if (document.querySelector(pageSelector)) {
      callback();
    }
  }
};
