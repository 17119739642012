const $ = require('jquery');

// check if we have a response from the google captcha
$(document).ready(() => {
  $('form.webform-client-form').submit(e => {
    const $this = $(e.currentTarget);
    if ($this.find('div[id^="google_recaptcha_webform"]').length) {
      /* eslint-disable no-undef */
      const response = grecaptcha.getResponse();
      if (response.length) {
        // remove error message on good submit
        // to indicate sumbission was valid while page loads
        $this.find('.error--custom').remove();
        return true;
      }
      // remove one if there
      $this.find('.error--custom').remove();
      // only run if false
      $this
        .find('div[id^="google_recaptcha_webform"]')
        .after(
          '<div class="error error--custom" style="display:block;">Please complete captcha.</div>'
        );
      return false;
    }
  });
});
