/* eslint-disable class-methods-use-this */
const $ = require('jquery');
const merge = require('merge-deep');

const { keyCodes } = require('../lib/constants');

const $document = $(document);
const $html = $(document.documentElement);
const $body = $(document.body);

// =============================================================================

module.exports = class Menu {
  /** Default jQuery selector for the component */
  static get DEFAULT_SELECTOR() {
    return '.block-menu-block';
  }

  /** Default configuration options */
  static get DEFAULT_OPTIONS() {
    return {
      hasOverlay: false,

      selectors: {
        hamburger: '.hamburger',
        parentItem: '.menu__item--parent',
        firstLevelLink: '.menu__link--level-1',
        secondLevelLink: '.menu__link--level-2',
      },

      classes: {
        open: 'is-open',
        bodyOverlay: 'js-overlay',
      },
    };
  }

  /**
   * Names of events
   */
  static get events() {
    return {
      OPEN: '@@Menu:open',
      CLOSE: '@@Menu:close',
    };
  }

  static create(...args) {
    return new this(...args);
  }

  /**
   * Constructor for the component
   *
   * @param {string|Element|JQuery} selector
   * @param {GalleryConfig} options
   */
  constructor(selector = Menu.DEFAULT_SELECTOR, options = {}) {
    this.config = merge(Menu.DEFAULT_OPTIONS, options);

    this.$menu = $(selector);
    this.$submenus = this.$menu.find(this.config.selectors.parentItem);
    this.$hamburger = this.$menu.find(this.config.selectors.hamburger);

    if (this.config.hasOverlay) {
      this.$menuOverlay = $('<div>').addClass('menu-overlay');
      this.$menuOverlay.appendTo($body);
    } else {
      this.$menuOverlay = $([]);
    }

    this.state = {
      isOpen: false,
      $activeSubmenu: null,
    };

    this.render();
    this.addListeners();
  }

  addListeners() {
    this.$hamburger.on('click', () => {
      this.$hamburger.blur();
      this.toggle();
    });

    this.$menu.on('click', this.config.selectors.parentItem, e => {
      // Don't close the submenu if we've actually clicked on a link
      if ($(e.target).is(this.config.selectors.secondLevelLink)) {
        return;
      }

      this.toggleSubmenu($(e.currentTarget));
    });

    // Close the menu when the users presses <ESC>
    $document.on('keyup', e => {
      if (e.keyCode === keyCodes.ESC) {
        this.close();
      }
    });

    // Close the menu when the user clicks outside it
    $document.on('click tap', e => {
      const isClickInMenu = $.contains(this.$menu[0], e.target);

      if (!isClickInMenu) {
        this.close();
      }
    });
  }

  setState(newState = {}) {
    Object.assign(this.state, newState);
    this.render();
  }

  toggle() {
    if (this.state.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this.setState({ isOpen: true });
    $document.trigger(Menu.events.OPEN, this);
  }

  close() {
    this.setState({
      isOpen: false,
      $activeSubmenu: null,
    });

    $document.trigger(Menu.events.CLOSE, this);
  }

  /** @param {JQuery} $submenu */
  toggleSubmenu($submenu) {
    if ($submenu.is(this.state.$activeSubmenu)) {
      this.setState({ $activeSubmenu: null });
    } else {
      this.setState({ $activeSubmenu: $submenu });
    }
  }

  render() {
    /** @type {JQuery|null} */
    const $activeSubmenu = this.state.$activeSubmenu;
    const isOpen = this.state.isOpen;

    $body.toggleClass('with-open-menu', isOpen);
    this.$menu.toggleClass(this.config.classes.open, isOpen);

    // Update submenus
    this.$submenus.each((index, el) => {
      const $submenu = $(el);
      $submenu.toggleClass(
        this.config.classes.open,
        $submenu.is($activeSubmenu)
      );
    });

    // Update the hamburger
    const hamburgerAction = isOpen ? 'Close' : 'Open';
    const hamburgerLabel = `${hamburgerAction} the Main Menu`;

    this.$hamburger
      .toggleClass(this.config.classes.open, isOpen)
      .attr('title', hamburgerLabel)
      .attr('aria-label', hamburgerLabel);

    // Toggle the overlay
    if (this.config.hasOverlay) {
      this.$menuOverlay.toggleClass(
        this.config.classes.open,
        this.state.isOpen
      );

      $html.toggleClass(this.config.classes.bodyOverlay, this.state.isOpen);
    }
  }
};
