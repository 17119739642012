const $ = require('jquery');

// =============================================================================
// Components
// -----------------------------------------------------------------------------

const DropCap = require('./components/DropCap');
const Hero = require('./components/Hero');
const Menu = require('./components/Menu');
const Textfield = require('./components/Textfield');
require('./components/forms');

Menu.create('.menu-panel', { hasOverlay: true });

DropCap.createMultiple(`
  .property__body > .field-name-body,
  .paragraphs-item-text-two-column .field-item:first-child
`);

Hero.create({
  fullHeight: `
    .front,
    .node-type-thank-you-page,
    .node-type-error-page,
  `,
});

Textfield.createMultiple();

// Make sure heroes are full height where they need to be

// -----------------------------------------------------------------------------

require('./lib/drupal');
require('./lib/smooth-scroll');
require('./lib/highlight');

require('./polyfills/object-assign-polyfill');

require('smartquotes')();

// =============================================================================
// Pages
// -----------------------------------------------------------------------------

require('./pages/property');
require('./pages/front');
require('./pages/community-involvement');
require('./pages/thank-you');

// =============================================================================
// Debugging
// -----------------------------------------------------------------------------

// Expose our version of jQuery to the JS console
window.$ = $;
