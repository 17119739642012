'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

// Custom Types
// =============================================================================

/**
 * Map containing breakpoints to use
 *
 * @typedef {Object.<string, number[]>} BreakpointMap
 */

/**
 * Query options bag
 *
 * @typedef {Object} QueryOpts
 *
 * @property {boolean} [false] exact
 *   Whether to use exact (`true`) or approximate (`false`) widths
 */

// Constants
// =============================================================================

/**
 * The maximum possible width of the window
 *
 * Uses Number.MAX_SAFE_INTEGER if available, falling back to the defined value
 * of Number.MAX_SAFE_INTEGER.
 *
 * @type {number}
 */
var MAX_WIDTH = Number.MAX_SAFE_INTEGER || 9007199254740991;

/**
 * Default breakpoints to use.
 *
 * These can be changed by calling `viewport.configure({ breakpoints: {} })`.
 *
 * @type {Object.<string, number[]>}
 */
var DEFAULT_BREAKPOINTS = {
  'xx-small': [0, 480],
  'x-small': [480, 600],
  small: [600, 800],
  medium: [800, 1000],
  large: [1000, 1200],
  'x-large': [1200, 1400],
  'xx-large': [1400, 1600],
  'xxx-large': [1600, MAX_WIDTH]
};

/**
 * Name of breakpoint events
 *
 * @type {Object.<string, string>}
 */
var EVENTS = {
  BREAKPOINT_CHANGED: '@@viewport:breakpoint-changed'
};

/**
 * Wrapper / polyfill for window.matchMedia
 *
 * @type {Function|null}
 */
var mq = getMatchMedia();

// Helper Functions
// =============================================================================

/**
 * Determines whether `num` is within the interval `[min, max)`
 *
 * @param {number} num - Number to test
 * @param {number} min - Left endpoint of the interval (inclusive)
 * @param {number} max - Right endpoint of the interval (exclusive)
 *
 * @return {boolean}
 */
function _isBetween(num, min, max) {
  return num >= min && num < max;
}

/**
 * Returns a wrapped media matching function
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries/Testing_media_queries
 *
 * @return {Function|null}
 */
function getMatchMedia() {
  if (window.matchMedia) {
    // For browsers that support window.matchMedia
    return function (mediaQuery) {
      return window.matchMedia(mediaQuery).matches;
    };
  } else if (window.msMatchMedia) {
    // For IE
    return function (mediaQuery) {
      return window.msMatchMedia(mediaQuery).matches;
    };
  }

  // No support :(
  return null;
}

/**
 * Fires a custom event on the provided element
 *
 * @param {DOMNode} el
 *   Element to fire the event on
 *
 * @param {string} eventType
 *   Type of event to fire
 *
 * @param {Object} [detail]
 *   Additional data to attach to the event
 */
function trigger(el, eventType, detail) {
  var event = void 0;

  if (window.CustomEvent) {
    event = new CustomEvent(eventType, { detail: detail });
  } else {
    event = document.createEvent(eventType);
    event.initCustomEvent(eventType, true, true, detail);
  }

  el.dispatchEvent(event);
}

/**
 * Normalizes a breakpoint map
 *
 * @private
 *
 * @param {BreakpointMap} breakpoints
 *
 * @return {BreakpointMap}
 */
function normalizeBreakpoints(breakpoints) {
  var normalizedBreakpoints = {};

  Object.keys(breakpoints).forEach(function (breakpoint) {
    // Ensure that each breakpoint has a max width
    var _breakpoints$breakpoi = _slicedToArray(breakpoints[breakpoint], 2);

    var min = _breakpoints$breakpoi[0];
    var _breakpoints$breakpoi2 = _breakpoints$breakpoi[1];
    var max = _breakpoints$breakpoi2 === undefined ? MAX_WIDTH : _breakpoints$breakpoi2;


    normalizedBreakpoints[breakpoint] = [min, max];
  });

  return normalizedBreakpoints;
}

// Breakpoints Class
// =============================================================================

var Viewport = function () {
  _createClass(Viewport, [{
    key: 'events',

    /**
     * Hash containing names of various events
     *
     * Event Key             | Triggered when                    | Detail
     * ----------------------|--------------------------------------|---------
     * `BREAKPOINT_CHANGED`  | Viewport changes to a new breakpoint | `{ from: string, to: string }`
     *
     * @property {Object.<string, string>}
     */
    get: function get() {
      return EVENTS;
    }

    /**
     * Create a new Viewport object
     *
     * @constructor
     */

  }]);

  function Viewport() {
    _classCallCheck(this, Viewport);

    this.breakpoints = {};
    this.breakpointNames = [];

    this.configure({
      breakpoints: DEFAULT_BREAKPOINTS,
      exact: false,
      unit: 'px'
    });

    this.attachEmitter(window);
  }

  /**
   * Configures the Breakpoints instance
   *
   * Because we're exporting a singleton, this is useful to change settings on
   * the exported instance.
   *
   * @param {Object} [opts={}]
   *   Query options
   *
   * @param {Object} [opts.breakpoints=null]
   *   Breakpoints to use
   *
   * @param {boolean} [opts.exact=false]
   *   Whether to calculate breakpoints using exact or approximate widths
   *
   * @return {void}
   */


  _createClass(Viewport, [{
    key: 'configure',
    value: function configure() {
      var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      if (opts.breakpoints != null) {
        this.breakpoints = normalizeBreakpoints(opts.breakpoints);

        // Pre-populate the list of names to avoid having to call Object.keys()
        // each time we call Breakpoints.currentBreakpoint
        this.breakpointNames = Object.keys(this.breakpoints);
      }

      if (opts.exact != null) {
        this.exact = opts.exact;
      }

      if (opts.unit != null) {
        this.unit = opts.unit;
      }
    }

    /**
     * Attaches an event emitter ot the provided element
     *
     * @private
     *
     * @param {DOMNode} [window] el
     *   Element to dispatch the events to
     */

  }, {
    key: 'attachEmitter',
    value: function attachEmitter() {
      var _this = this;

      var el = arguments.length <= 0 || arguments[0] === undefined ? window : arguments[0];
      var BREAKPOINT_CHANGED = this.events.BREAKPOINT_CHANGED;


      var prevBreakpoint = this.currentBreakpoint();

      // Dispatch BREAKPOINT_CHANGED event on resize
      el.addEventListener('resize', function () {
        var newBreakpoint = _this.currentBreakpoint();

        if (newBreakpoint !== prevBreakpoint) {
          trigger(el, BREAKPOINT_CHANGED, {
            from: prevBreakpoint,
            to: newBreakpoint
          });

          prevBreakpoint = newBreakpoint;
        }
      });
    }

    /**
     * Returns either the exact or approximate viewport width, depending on the
     * options parameter or Breakpoint instance
     *
     * @param {Object} [opts={}]
     *   Options bag
     *
     * @param {Object} [opts.exact=false]
     *   Use exact or approximate widths
     *
     * @return {number}
     */

  }, {
    key: 'width',
    value: function width() {
      var unit = arguments.length <= 0 || arguments[0] === undefined ? 'px' : arguments[0];
      var opts = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      var config = {
        exact: this.exact,
        unit: this.unit
      };

      if (typeof unit === 'string') {
        config.unit = unit;
      }if ((typeof unit === 'undefined' ? 'undefined' : _typeof(unit)) === 'object') {
        _extends(config, unit);
      }

      if ((typeof opts === 'undefined' ? 'undefined' : _typeof(opts)) === 'object') {
        _extends(config, opts);
      }

      return config.exact || config.unit !== 'px' ? this.exactWidth({ unit: config.unit }) : this.approxWidth();
    }

    /**
     * Gets the approximate viewport width in pixels, normalizing for
     * cross-browser differences.
     *
     * This is much, much faster than `Viewport#width`
     *
     * @private
     *
     * @return {number}
     */

  }, {
    key: 'approxWidth',
    value: function approxWidth() {
      var documentWidth = document.documentElement.clientWidth;
      var windowWidth = window.innerWidth;

      return Math.max(windowWidth, documentWidth);
    }

    /**
     * Returns the exact CSS viewport width `window.matchMedia` queries.
     *
     * This method is about 75x slower than `Viewport#approxWidth` (1,800,000 ops
     * / sec vs 30,000 ops / sec), so only use this method if you need the
     * viewport width in non-pixel units, or need extreme accuracy on ultra high
     * res displays.
     *
     * @see https://gist.github.com/ryanve/7924792
     *
     * @private
     *
     * @return {number|null}
     */

  }, {
    key: 'exactWidth',
    value: function exactWidth() {
      var unit = arguments.length <= 0 || arguments[0] === undefined ? this.unit : arguments[0];
      var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      // window.matchMedia (or IE equivalent) is not supported :(
      if (mq == null) {
        throw new Error(['Media query tests are not supported by this browser.', 'Please use `breakpoints.getApproxViewportWidth` instead.'].join(' '));
      }

      var config = { unit: this.unit };

      if (typeof unit === 'string') {
        config.unit = unit;
      } else if ((typeof unit === 'undefined' ? 'undefined' : _typeof(unit)) === 'object') {
        _extends(config, unit);
      }

      if ((typeof ops === 'undefined' ? 'undefined' : _typeof(ops)) === 'object') {
        _extends(config, opts);
      }

      // Based on the unit, try to be smart about where to start from
      if (config.startFrom == null) {
        var approxWidth = this.approxWidth();

        switch (config.unit) {
          case 'pc':
          case 'rem':
          case 'em':
          case 'ch':
            config.startFrom = approxWidth / 16;
            break;

          case 'ex':
            config.startFrom = approxWidth / 8;
            break;

          case 'in':
            config.startFrom = approxWidth / 96;
            break;

          case 'cm':
            config.startFrom = approxWidth / 37.8;
            break;

          case 'mm':
            config.startFrom = approxWidth / 3.78;
            break;

          case 'pt':
            config.startFrom = approxWidth / 1.33;
            break;

          default:
            config.startFrom = approxWidth;
        }
      }

      var startFrom = config.startFrom;
      var stepSize = config.stepSize;


      for (var testWidth = startFrom; testWidth >= 0; testWidth += stepSize) {
        // Is the window width <= the test width?
        var isBelowOrEqualsTestWidth = mq('(max-width:' + testWidth + config.unit + ')');

        // Is the window width >= the test width?
        var isAboveOrEqualsTestWidth = mq('(min-width:' + testWidth + config.unit + ')');

        // If satisfies both queries, then we know that we've found a match
        var doesEqualTestWidth = isBelowOrEqualsTestWidth && isAboveOrEqualsTestWidth;

        // Return the floored value if it makes an exact match, otherwise return
        // the raw value.
        if (doesEqualTestWidth) {
          var flooredWidth = Math.floor(testWidth);

          return mq('(width:' + flooredWidth + config.unit) ? flooredWidth : testWidth;
        }

        if (isBelowOrEqualsTestWidth && stepSize > 0) {
          // If the window width is below the test value, but we're currently
          // looking for bigger numbers, flip the search direction and increase
          // the precision. Otherwise, just leaves the step size alone and
          // continue searching for smaller numbers.
          stepSize /= -2;
        } else if (isAboveOrEqualsTestWidth && stepSize < 0) {
          // Likewise, if the window width is above the test value, but we're
          // currently looking for smaller numbers, flip the search direction and
          // increase the precision.
          stepSize /= -2;
        }
      }

      // Nothing matched
      return null;
    }

    /**
     * Gets the name of the current breakpoint
     *
     * @param {Object} [opts={}]
     *   Options bag
     *
     * @param {Object} [opts.exact=false]
     *   Use exact or approximate widths for determining the breakpoint
     *
     * @return {string}
     */

  }, {
    key: 'currentBreakpoint',
    value: function currentBreakpoint(opts) {
      var width = this.width(opts);

      var breakpoints = this.breakpoints;
      var breakpointNames = this.breakpointNames;
      var numBreakpoints = breakpointNames.length;

      // This loop gets called on each resize event, so we need to make sure it's
      // fast. When using `exact: false`, the current code runs at about 1,800,000
      // ops / sec. on my old laptop, compared to ~100,000 ops/sec using fancy ES6
      // features like object spread, or ~1,000,000 using a `forEach` loop.
      //
      // eslint-disable-next-line no-plusplus
      for (var i = 0; i < numBreakpoints; i++) {
        var breakpointName = breakpointNames[i];
        var breakpoint = breakpoints[breakpointName];

        if (_isBetween(width, breakpoint[0], breakpoint[1])) {
          return breakpointName;
        }
      }

      return null;
    }

    /**
     * Determines whether the current window width falls within the provided
     * breakpoint.
     *
     * @param {string|number} breakpoint
     *   Name of breakpoint to check
     *
     * @param {Object} [opts={}]
     *   Options bag
     *
     * @param {Object} [opts.exact=false]
     *   Use exact or approximate widths
     *
     * @return {boolean}
     */

  }, {
    key: 'is',
    value: function is(breakpoint, opts) {
      var windowWidth = this.width(opts);

      if (typeof breakpoint === 'number') {
        return windowWidth === breakpoint;
      }

      return _isBetween.apply(undefined, [windowWidth].concat(_toConsumableArray(this.breakpoints[breakpoint])));
    }

    /**
     * Determines whether the current window width is inside the interval
     * specified by `[breakpoint, Number.MAX_SAFE_INTEGER)`.
     *
     * `breakpoint` can be either the name of a breakpoint or a number value
     * representing pixels.
     *
     * @param {string|number} breakpoint
     *   Lower boundary, inclusive
     *
     * @param {Object} [opts={}]
     *   Options bag
     *
     * @param {Object} [opts.exact=false]
     *   Use exact or approximate widths
     *
     * @return {boolean}
     */

  }, {
    key: 'isAbove',
    value: function isAbove(breakpoint, opts) {
      var windowWidth = this.width(opts);

      var testWidth = typeof breakpoint === 'number' ? breakpoint : this.breakpoints[breakpoint][0];

      return windowWidth >= testWidth;
    }

    /**
     * Determines whether the current window width is inside the interval
     * specified by `[0, breakpoint)`.
     *
     * `breakpoint` can be either the name of a breakpoint or a number value
     * representing pixels.
     *
     * @param {string|number} breakpoint
     *   Upper boundary, exclusive
     *
     * @param {Object} [opts={}]
     *   Options bag
     *
     * @param {Object} [opts.exact=false]
     *   Use exact or approximate widths
     *
     * @return {boolean}
     */

  }, {
    key: 'isBelow',
    value: function isBelow(breakpoint, opts) {
      var windowWidth = this.width(opts);

      var testWidth = typeof breakpoint === 'number' ? breakpoint : this.breakpoints[breakpoint][0];

      return windowWidth < testWidth;
    }

    /**
     * Determines whether the current window width is inside the interval
     * specified by `[lower, upper)`.
     *
     * Both boundaries can be either the name of a breakpoint or a number value
     * representing pixels.
     *
     * @param {string|number} lower
     *   Lower boundary, inclusive
     *
     * @param {string|number} upper
     *   Upper boundary, exclusive
     *
     * @param {Object} [opts={}]
     *   Options bag
     *
     * @param {Object} [opts.exact=false]
     *   Use exact or approximate widths
     *
     * @return {boolean}
     */

  }, {
    key: 'isBetween',
    value: function isBetween(lower, upper, opts) {
      return this.isAbove(lower, opts) && this.isBelow(upper, opts);
    }

    /**
     * Performs an arbitrary CSS media query
     *
     * We're exporting a singleton, so it makes no sense to have a static method.
     *
     * @param {string} cssQuery
     *   CSS media query
     *
     * @return {boolean}
     */
    // eslint-disable-next-line class-methods-use-this

  }, {
    key: 'query',
    value: function query(cssQuery) {
      return mq(cssQuery);
    }
  }]);

  return Viewport;
}();

// Exports
// =============================================================================
//
// Export a singleton instance, so that all scripts that import this module will
// have access to the breakpoints without having to reconfigure them.

// Create our singleton...


var viewport = new Viewport();

// And export it
module.exports = viewport;
