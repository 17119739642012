const $ = require('jquery');
const Modernizr = require('modernizr');
const merge = require('merge-deep');
require('jquery-hammerjs');
require('featherlight');
require('featherlight/src/featherlight.gallery');

// Prevent featherlight from automatically binding on document.ready
$.featherlight.autoBind = false;

// -----------------------------------------------------------------------------

/**
 * @typedef ShadowboxConfig
 *
 * @property {string} namespace
 *   Name of the events and css class prefix
 *
 * @property {string} targetAttr
 *   Attribute of the triggered element that contains the selector to the
 *   lightbox content
 *
 * @property {string|null} variant
 *   Class that will be added to change look of the lightbox
 *
 * @property {boolean} resetCss
 *   Reset all css
 *
 * @property {string|null} background
 *   Custom DOM for the background, wrapper and the closebutton
 *
 * @property {string} openTrigger
 *   Event that triggers the lightbox
 *
 * @property {string} closeTrigger
 *   Event that triggers the closing of the lightbox
 *
 * @property {string} filter
 *   Selector to filter events. Think $(...).on('click', filter, eventHandler)
 *
 * @property {string} root
 *   Where to append featherlights
 *
 * @property {number} openSpeed
 *   Duration of opening animation
 *
 * @property {number} closeSpeed
 *   Duration of closing animation
 *
 * @property {string} closeOnClick
 *   Close lightbox on click ('background', 'anywhere', or false)
 *
 * @property {boolean} closeOnEsc
 *   Close lightbox when pressing esc
 *
 * @property {string} closeIcon
 *   Close icon
 *
 * @property {string} loading
 *   Content to show while initial content is loading
 *
 * @property {boolean} persist
 *   If set, the content will persist and will be shown again when opened again.
 *   'shared' is a special value when binding multiple elements for them to
 *   share the same content
 *
 * @property {string} otherClose
 *   Selector for alternate close buttons (e.g. "a.close")
 *
 * @property {function} beforeOpen
 *   Called before open. can return false to prevent opening of lightbox. Gets
 *   event as parameter, this contains all data
 *
 * @property {function} beforeContent
 *   Called when content is about to be presented. `this` is the featherlight
 *   instance. Gets event as parameter
 *
 * @property {function} beforeClose
 *   Called before close. can return false to prevent opening of lightbox.
 *  `this` is the featherlight instance. Gets event as parameter
 *
 * @property {function} afterOpen
 *   Called after open. `this` is the featherlight instance. Gets event as
 *   parameter
 *
 * @property {function} afterContent
 *   Called after content is ready and has been set. Gets event as parameter,
 *   this contains all data
 *
 * @property {function} afterClose
 *   Called after close. `this` is the featherlight instance. Gets event as
 *   parameter
 *
 * @property {function} onKeyUp
 *   Called on key up for the frontmost featherlight
 *
 * @property {function} onResize
 *   Called after new content and when a window is resized
 *
 * @property {string} type
 *   Specify content type. If unset, it will check for the targetAttrs value.
 *
 * @property {string[]} contentFilters
 *   List of content filters to use to determine the content
 *
 * @property {string|Element|JQuery} content
 *   Target content of the shadowbox
 *
 * @property {boolean} isGallery
 *   Whether the shadowbox is a gallery
 *
 * @property {string} previousIcon
 *   Code that is used as previous icon
 *
 * @property {string} nextIcon
 *   Code that is used as next icon
 *
 * @property {number} galleryFadeIn
 *   fadeIn speed when slide is loaded
 *
 * @property {number} galleryFadeOut
 *   fadeOut speed before slide is loaded
 */

// -----------------------------------------------------------------------------

const $html = $(document.documentElement);

module.exports = class Shadowbox {
  /** Default selector */
  static get DEFAULT_SELECTOR() {
    return '.shadowbox';
  }

  /**
   * Default configuration options
   * @return {ShadowboxConfig}
   */
  static get DEFAULT_OPTIONS() {
    return {
      namespace: 'shadowbox',
      targetAttr: 'data-shadowbox-ref',
      variant: null,
      resetCss: false,
      background: null,
      openTrigger: 'click',
      closeTrigger: 'click',
      filter: null,
      root: 'body',
      openSpeed: 250,
      closeSpeed: 250,
      closeOnClick: 'background',
      closeOnEsc: true,
      closeIcon: '&times;',
      loading: '',
      persist: false,
      otherClose: null,
      beforeOpen: $.noop,
      beforeContent: $.noop,
      beforeClose: () => $html.removeClass('js-overlay'),
      afterOpen: () => $html.addClass('js-overlay'),
      afterContent: $.noop,
      afterClose: $.noop,
      onKeyUp: $.noop,
      onResize: $.noop,
      type: null,
      contentFilters: ['jquery', 'image', 'html', 'ajax', 'text'],

      content: null,

      // Gallery Specific Options
      previousIcon: '&larr;',
      nextIcon: '&rarr;',
      galleryFadeIn: 100,
      galleryFadeOut: 300,

      // Component-specific options
      isGallery: false,
    };
  }

  static create(...args) {
    return new this(...args);
  }

  /**
   * Constructor for the component
   *
   * @param {string|Element|JQuery} selector
   * @param {GalleryConfig} opts
   */
  constructor(selector = Shadowbox.DEFAULT_SELECTOR, options = {}) {
    /** @type {ShadowboxConfig} */
    this.config = merge(Shadowbox.DEFAULT_OPTIONS, options);

    this.$shadowbox = $(selector);

    // Load any polyfills that are needed.
    this.polyfill();

    if (this.config.isGallery) {
      this.$shadowbox.featherlightGallery(this.config);
    } else {
      const $content =
        this.config.content == null
          ? $(this.$shadowbox.attr(this.config.targetAttr))
          : $(this.config.content);

      $content.attr('data-object-fit', 'contain');

      this.$shadowbox.featherlight($content, this.config);
    }
  }

  polyfill() {
    if (!Modernizr.objectfit) {
      this.config.beforeOpen = function handleBeforeOpen() {
        //
      };
    }
  }
};
