const $ = require('jquery');
const merge = require('merge-deep');
require('../lib/smooth-scroll');

const $window = $(window);

// -----------------------------------------------------------------------------

/**
 * @typedef DrawerItem
 *
 * @property {JQuery} $item
 * @property {JQuery} $toggler
 * @property {JQuery} $content
 */

// -----------------------------------------------------------------------------

module.exports = class Drawer {
  /**
   * Default selector
   * @type {string|Element|JQuery}
   */
  static get DEFAULT_SELECTOR() {
    return '.drawer';
  }

  /**
   * Default component options
   * @type {Object}
   */
  static get DEFAULT_OPTIONS() {
    return {
      selectors: {
        drawerContents: '.drawer__contents',
        closeTrigger: '.drawer__close-button',
        pointer: '.drawer__pointer',
        items: '.drawer__item',
        itemToggler: '.drawer__toggler',
        itemContent: '.drawer__content',
      },

      classes: {
        open: 'is-open',
      },
    };
  }

  /**
   * Creates an instance of the component.
   *
   * Useful when creating a component primarily for its side effects
   *
   * @see http://eslint.org/docs/rules/no-new
   *
   * @return {Drawer}
   */
  static create(...args) {
    return new this(...args);
  }

  /**
   * Creates an instance of the component for each item in the matched set.
   *
   * @param {string|Element|JQuery} selector
   * @param {Object} options
   * @return {Drawer[]}
   */
  static createMultiple(selector = Drawer.DEFAULT_SELECTOR, options = {}) {
    return $(selector).toArray().map(el => new this(el, options));
  }

  /**
   * Constructor for the component
   *
   * @param {string|Element|JQuery} selector
   * @param {Object} options
   */
  constructor(selector = Drawer.DEFAULT_SELECTOR, options = {}) {
    this.config = merge(Drawer.DEFAULT_OPTIONS, options);

    this.$drawer = $(selector);
    this.$contents = this.$drawer.find(this.config.selectors.drawerContents);
    this.$closeTrigger = this.$drawer.find(this.config.selectors.closeTrigger);
    this.$pointer = this.$drawer.find(this.config.selectors.pointer);

    /** @type {DrawerItem[]} */
    this.items = $(this.config.selectors.items).toArray().map(el => {
      const $item = $(el);
      const $toggler = $item.find(this.config.selectors.itemToggler);
      const $content = $item.find(this.config.selectors.itemContent);

      return { $item, $toggler, $content };
    });

    this.state = {
      isOpen: false,
      activeItemIndex: null,
    };

    // this.$drawer.hide();

    this.addListeners();
    this.render();
  }

  /**
   * Bind event listeners needed by this component
   */
  addListeners() {
    this.items.forEach((item, index) => {
      if (!item.$content.length) {
        return;
      }

      item.$toggler.on('click', e => {
        e.preventDefault();
        this.toggle(index);
      });
    });

    this.$closeTrigger.on('click', () => this.close());

    $window.on('resize', () => this.render());
  }

  /**
   * Update the state of the component
   *
   * @param {Object} newState
   */
  setState(newState) {
    Object.assign(this.state, newState);
    this.render();
  }

  /**
   * @param {number} itemIndex
   */
  toggle(itemIndex) {
    if (this.state.isOpen && this.state.activeItemIndex === itemIndex) {
      this.close();
    } else {
      this.open(itemIndex);
    }
  }

  /**
   * @param {DrawerItem} item
   */
  open(itemIndex) {
    this.setState({
      isOpen: true,
      activeItemIndex: itemIndex,
    });

    this.items[itemIndex].$toggler.smoothScrollTo();
    // this.$drawer.smoothScrollTo();
  }

  close() {
    this.setState({
      isOpen: false,
      activeItemIndex: null,
    });
  }

  /**
   * Perform DOM manipulations based on the state of the component
   */
  render() {
    // Update the drawer class
    this.$drawer.toggleClass(this.config.classes.open, this.state.isOpen);

    // Toggle the active state of icons, based on whether their index matches
    // the current active item index.
    this.items.forEach((item, index) =>
      item.$toggler.toggleClass(
        this.config.classes.open,
        index === this.state.activeItemIndex
      )
    );

    // Close out and exit early, resetting the pointer's left coordinate to
    // prevent it from sliding between items after re-opening the drawer
    if (!this.state.isOpen) {
      this.$contents.slideUp(() => this.$pointer.css('left', ''));
      return;
    }

    // Add the new content to the drawer
    const activeItem = this.items[this.state.activeItemIndex];
    const $newContent = activeItem.$content.clone().children();

    this.$contents
      .empty()
      .data('current-item-index', this.state.activeItemIndex)
      .append($newContent)
      .wrapInner('<div>')
      .slideDown();

    // Make the pointer point to center of the current toggler
    const togglerLeft = activeItem.$toggler.position().left;
    const togglerWidth = activeItem.$toggler.outerWidth();
    const togglerCenter = togglerLeft + togglerWidth * 0.5;

    this.$pointer.css('left', togglerCenter);
  }
};
